import React from 'react';
import { Outlet } from 'react-router-dom';
import LeftMenu from './components/LeftMenu';
import { DroneContext } from './contexts/drone';
import { useNavigate } from 'react-router-dom';

function Layout() {
  const droneCtx = React.useContext(DroneContext);

  const [oldDroneID, setOldDroneID] = React.useState<string | null | undefined>();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (droneCtx?.droneID && oldDroneID !== droneCtx?.droneID) {
      navigate('/drone');
    }
    setOldDroneID(droneCtx?.droneID);
  }, [droneCtx?.droneID]);

  return (
    <React.Fragment>
      <Outlet />
      <LeftMenu />
    </React.Fragment>
  );
}

export default Layout;
