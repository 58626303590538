import { Box } from "@mui/material";
import React from "react";
import useSelectedDrone from "../hooks/useSelectedDrone";
import FabProgress from "./FabProgress";
import HomeIcon from '@mui/icons-material/Home';
import FabLoading from "./FabLoading";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import useApi from "../hooks/useApi";
import useController from "../hooks/useController";
import useUser from "../hooks/useUser";

export interface DroneActionsButtonProps {
  style?: React.CSSProperties;
}

function DroneActionsButton(props: DroneActionsButtonProps) {
  const [fpProgress, setFpProgress] = React.useState<number | null>(null);
  const [showPlayButton, setShowPlayButton] = React.useState<boolean>(false);
  const [playButtonLoading, setPlayButtonLoading] = React.useState<boolean>(false);
  const [showStopButton, setShowStopButton] = React.useState<boolean>(false);
  const [stopButtonLoading, setStopButtonLoading] = React.useState<boolean>(false);
  const [showHome, setShowHome] = React.useState<boolean>(false);
  const [homeButtonLoading, setHomeButtonLoading] = React.useState<boolean>(false);

  const style = props.style ? Object.assign({}, props.style) : {};
  style.transform = 'translateZ(0px)';
  style.flexGrow = 1;
  style.display = 'flex';
  style.flexDirection = 'row';

  const { selectedDroneID, selectedDroneStatus } = useSelectedDrone();
  const { post } = useApi();
  const { goHome } = useController();
  const { hasRole } = useUser();

  const handlePlay = () => {
    if (!selectedDroneID) {
      return;
    }

    setPlayButtonLoading(true);
    post(`/drones/${selectedDroneID}/flightplan/start`).then(() => {
      setTimeout(() => {
        setPlayButtonLoading(false);
      }, 3000);
    });
  };

  const handleStop = () => {
    if (!selectedDroneID) {
      return;
    }

    setStopButtonLoading(true);
    post(`/drones/${selectedDroneID}/flightplan/stop`).then(() => {
      setTimeout(() => {
        setStopButtonLoading(false);
      }, 3000);
    });
  }

  const handleHome = () => {
    if (!selectedDroneID) {
      return;
    }

    setHomeButtonLoading(true);
    goHome(selectedDroneID).then(() => {
      setTimeout(() => {
        setHomeButtonLoading(false);
      }, 3000);
    });
  }

  React.useEffect(() => {
    if (!selectedDroneStatus) {
      setFpProgress(null);
      setShowHome(false);
      setShowPlayButton(false);
      setShowStopButton(false);
      return;
    }

    if (selectedDroneStatus.metrics?.state+'' === 'FLIGHT_PLAN') {
      setShowStopButton(true);
    } else {
      setShowStopButton(false);
    }

    if (selectedDroneStatus.metrics?.state+'' === 'LANDED' || selectedDroneStatus.metrics?.state+'' === 'FLYING') {
      setShowPlayButton(true);
    } else {
      setShowPlayButton(false);
    }

    if (selectedDroneStatus.metrics?.state+'' !== 'LANDED' && selectedDroneStatus.metrics?.state+'' !== 'UNKNOWN' && selectedDroneStatus.metrics?.state+'' !== 'DOWNLOADING') {
      setShowHome(true);
    } else {
      setShowHome(false);
    }

    if (selectedDroneStatus.flightProgress) {
      const progress = selectedDroneStatus.flightProgress.currentCommand / selectedDroneStatus.flightProgress.totalCommands;
      setFpProgress(!Number.isNaN(progress) ? progress : null);
    } else {
      setFpProgress(null);
    }
  }, [selectedDroneStatus]);

  return (
    <Box sx={style}>
      { fpProgress !== null && <FabProgress progress={fpProgress} /> }
      { showPlayButton && hasRole('bvlos') && <FabLoading onClick={handlePlay} loading={playButtonLoading}>
        <PlayArrowIcon />
      </FabLoading> }
      { showStopButton && hasRole('bvlos') && <FabLoading onClick={handleStop} loading={stopButtonLoading}>
        <StopIcon />
      </FabLoading> }
      { showHome && hasRole('bvlos') && <FabLoading onClick={handleHome} loading={homeButtonLoading}>
        <HomeIcon />
      </FabLoading> }
    </Box>
  );
}

export default DroneActionsButton;
