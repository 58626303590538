import React from 'react';
import { Slider, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import useController from '../hooks/useController';
import useSelectedDrone from '../hooks/useSelectedDrone';
import { DroneContext } from '../contexts/drone';
import { DroneStatus } from '../grpc/ui_pb';
import useInterval from '../hooks/useInterval';
import useApi from '../hooks/useApi';

interface CameraTiltSliderProps {
  style?: React.CSSProperties;
}

const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    backgroundColor: '#fff',
  },
  '& .MuiSlider-track': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiSlider-rail': {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#fff',
    height: 8,
    width: 1,
    marginLeft: -1,
  },
  '& .MuiSlider-markLabel': {
    color: '#fff',
    fontSize: '0.75rem',
  },
}));

const marks = [
  { value: 20, label: '20°' },
  { value: 0, label: '0°' },
  { value: -15, label: '-15°' },
  { value: -30, label: '-30°' },
  { value: -45, label: '-45°' },
  { value: -60, label: '-60°' },
  { value: -75, label: '-75°' },
  { value: -90, label: '-90°' },
  { value: -105, label: '-105°' },
  { value: -120, label: '-120°' },
];

export function CameraTiltSlider({ style }: CameraTiltSliderProps) {
  const [droneStatus, setDroneStatus] = React.useState<DroneStatus.AsObject | null>(null);
  const [ticker, setTicker] = React.useState<number>(0);
  const [tiltValue, setTiltValue] = React.useState<number | undefined>();

  const droneCtx = React.useContext(DroneContext);

  const { tiltCamera } = useController();
  const { selectedDroneID } = useSelectedDrone();
  const { get } = useApi();

  useInterval(() => {
    setTicker(ticker + 1);
  }, 1000);

  const handleChange = (_event: Event, newValue: number | number[]) => {
    if (selectedDroneID) {
      tiltCamera(selectedDroneID, newValue as number, 0).catch(console.error);
    }
  };

  React.useEffect(() => {
    if (!droneCtx?.droneID) {
      setDroneStatus(null);
      return;
    }
    get(`/drones/${droneCtx.droneID}/status`).then((res: any) => {
      if (res) {
        setDroneStatus(res);
      }
    });
  }, [droneCtx?.droneID, ticker]);

  React.useEffect(() => {
    if (droneStatus) {
      setTiltValue(droneStatus.metrics?.gimbalAttitude?.pitch);
    }
  }, [droneStatus]);

  if (tiltValue === undefined) {
    return null;
  }

  return (
    <Box 
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...style
      }}
    >
      <StyledSlider
        orientation="vertical"
        value={tiltValue}
        onChange={handleChange}
        aria-label="Camera Tilt"
        min={-120}
        max={20}
        marks={marks}
        valueLabelDisplay="off"
        sx={{ 
          '& .MuiSlider-valueLabel': {
            backgroundColor: 'primary.main',
          }
        }}
      />
    </Box>
  );
}
