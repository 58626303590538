import { Box, CircularProgress, Fab } from '@mui/material';
import React from 'react';
import theme from '../theme';

export interface FabLoadingProps extends React.PropsWithChildren<{}> {
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

function FabLoading(props: FabLoadingProps) {
  const { loading, disabled, children, onClick } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Fab disabled={disabled} onClick={() => {
          if (onClick) {
            onClick();
          }
        }}>
          { children }
        </Fab>
        { loading &&
        <CircularProgress
          size={68}
          sx={{
            color: theme.palette.secondary.main,
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        /> }
      </Box>
    </Box>
  );
}

export default FabLoading;
