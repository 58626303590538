import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import MapView from './pages/MapView';
import { APIProvider } from '@vis.gl/react-google-maps';
import { LeftMenuContext, LeftMenuContextProps } from './components/LeftMenu';
import DroneView from './pages/DroneView';
import { PipContext, PipContextProps } from './components/Pip';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { PositionContext, defaultPositionManager } from './contexts/position';
import { DroneContext } from './contexts/drone';
import { OrganizationContext } from './contexts/organization';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, InMemoryCacheConfig, ObservableQuery } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { graphqlURL, gtmId } from './config';
import RoleGuard from './guards/RoleGuard';
import TagManager from 'react-gtm-module';
import TrackPageView from './components/TrackPageView';

function App() {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? 'AIzaSyD68kpit7GVfUExBXN8EwiKmBH9pk-34lA';

  const [ leftMenuOpen, setLeftMenuOpen ] = React.useState<boolean>(false);
  const leftMenuCtxValue: LeftMenuContextProps = { open: leftMenuOpen, setOpen: setLeftMenuOpen };

  const [ pipPage, setPipPage ] = React.useState<'map' | 'drone'>('drone');
  const pipCtxValue: PipContextProps = { page: pipPage, setPage: setPipPage };

  const [ selectedDroneID, setSelectedDroneID ] = React.useState<string | null>(null);
  const droneCtxValue = { droneID: selectedDroneID, setDroneID: setSelectedDroneID };

  const [ selectedOrganizationID, setSelectedOrganizationID ] = React.useState<string | null>(localStorage.getItem('currentOrganizationId'));
  const organizationCtxValue = { organizationID: selectedOrganizationID, setOrganizationID: setSelectedOrganizationID };

  const [ client, setClient ] = React.useState<ApolloClient<any> | null>(null);

  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

  const positionManager = defaultPositionManager;

  React.useEffect(() => {
    if (!gtmId) {
      console.warn('GTM_ID is not set');
      return;
    }

    const tagManagerArgs = {
      gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  // automatically sign-in
  React.useEffect(() => {
    if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  React.useEffect(() => {
    if (!auth.user || auth.user.expired) {
      return;
    }

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = auth.user?.access_token;
      // return the headers to the context so httpLink can read them
      const h = {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        }
      }
      if (selectedOrganizationID) {
        h.headers['current-organization'] = selectedOrganizationID;
      }
      return h;
    });
  
    const httpLink = createHttpLink({
      uri: graphqlURL,
    });

    if (!client) {
      const wait = (ms: number) => new Promise((res) => setTimeout(res, ms))
      const delayRefetchedQuery = async (observableQuery: ObservableQuery) => {
        await wait(100);
        observableQuery.refetch();
      }

      setClient(new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache({
          resultCaching: true,
        } as InMemoryCacheConfig),
        defaultOptions: {
          mutate: {
            onQueryUpdated: delayRefetchedQuery,
          },
        },
        connectToDevTools: true,
      }));
    } else {
      client.setLink(authLink.concat(httpLink));
    }
  }, [auth.user?.access_token]);

  // import active drone and organization from url
  React.useEffect(() => {
    if (!auth.user) {
      return;
    }

    const url = new URL(window.location.href);
    const droneID = url.searchParams.get('drone');
    const organizationID = url.searchParams.get('organization');

    if (organizationID) {
      setSelectedOrganizationID(organizationID);
    }
    if (droneID) {
      setSelectedDroneID(droneID);
    }

    if (droneID || organizationID) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [auth.user]);

  React.useEffect(() => {
    if (!auth.user) {
      return;
    }

    const currentOrganizationId = localStorage.getItem('currentOrganizationId');

    if (selectedOrganizationID) {
      localStorage.setItem('currentOrganizationId', selectedOrganizationID);
    }

    if (currentOrganizationId !== selectedOrganizationID) {
      window.location.reload();
    }
  }, [selectedOrganizationID, auth.user]);

  if (auth.isLoading || !client) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  return (
    <ApolloProvider client={client}>
      <APIProvider apiKey={apiKey}>
        <PositionContext.Provider value={positionManager}>
          <OrganizationContext.Provider value={organizationCtxValue}>
            <DroneContext.Provider value={droneCtxValue}>
              <PipContext.Provider value={pipCtxValue}>
                <LeftMenuContext.Provider value={leftMenuCtxValue}>
                  <BrowserRouter>
                    <TrackPageView />
                    <Routes>
                      <Route path="/" element={<RoleGuard requiredRoles={['visualization_live_stream']} featureName="LiveStream"><Layout /></RoleGuard>}>
                        <Route index element={<MapView />} />
                        <Route path="map" element={<MapView />} />
                        <Route path="drone" element={<DroneView />} />
                      </Route>
                    </Routes>
                  </BrowserRouter>
                </LeftMenuContext.Provider>
              </PipContext.Provider>
            </DroneContext.Provider>
          </OrganizationContext.Provider>
        </PositionContext.Provider>
      </APIProvider>
    </ApolloProvider>
  );
}

export default App;
